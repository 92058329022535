<template>
  <v-card
    id="video-preview"
  >
    <!-- actions -->
    <!-- <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition> -->

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in computedTabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`videos.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <video-tab-general
          :data-params="videoData"
          :data-transaction="transactionData"
          :tab="tab"
          :option="option"
        ></video-tab-general>
      </v-tab-item>

      <v-tab-item>
        <video-tab-player
          :data-params="videoData"
          :tab="tab"
          :option="option"
          :show-player="!isAfter30Days"
        ></video-tab-player>
      </v-tab-item>

      <v-tab-item>
        <video-tab-view
          :data-params="userViews"
          :tab="tab"
          :option="option"
        ></video-tab-view>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import { mdiTableArrowLeft, mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiAccountEyeOutline, mdiVideoOutline } from '@mdi/js'
import { getVideoUserViews, getVideo } from '@api'
import { useRouter, formatCurrency, after30Days } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import 'video.js/dist/video-js.css'
import usePermissions from '@core/utils/usePermissions'

import VideoTabGeneral from './VideoTabGeneral.vue'
import VideoTabPlayer from './VideoTabPlayer.vue'
import VideoTabView from './VideoTabView.vue'

export default {
  components: {
    VideoTabGeneral,
    VideoTabPlayer,
    VideoTabView,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Number,
      required: false,
      default: 2,
    },
    dataId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { route, router } = useRouter()
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const isAfter30Days = ref(false)

    // const videoDataId = ref(null)
    // const option = ref(1)
    const videoData = ref(null)
    const transactionData = ref(null)
    const pageData = ref(null)
    const filterData = ref(null)
    const userViews = ref([])
    const tab = ref(0)
    const tabs = ref([
      {
        title: 'general',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'video',
        icon: mdiVideoOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'views',
        icon: mdiAccountEyeOutline,
        disabled: false,
        hide: false,
      },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => !e.hide))
    const computedIsOpen = computed({
      get: () => props.isOpen,
      set: val => emit('update:is-open', val),
    })

    const goBack = () => {
      if (transactionData.value) {
        router.push({
          name: 'views-order-history-preview',
          params: {
            id: transactionData.value.id,
            dataParams: transactionData.value,
            option: 2,
          },
        })
      } else {
        // name: 'views-video-list',
        router.replace({
          name: 'views-video-view',
          params: {
            pageParams: pageData.value,
            filterParams: filterData.value,
          },
        })
      }
    }

    const initForm = async () => {
      transactionData.value = route.value.params.dataTransaction

      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      if (props.dataId) {
        const respVideo = await getVideo(props.dataId)
        if (respVideo.ok) {
          // videoData.value = route.value.params.dataParams
          videoData.value = respVideo.data
          isAfter30Days.value = after30Days(videoData.value.video_date)

          const resp = await getVideoUserViews(videoData.value.id)
          userViews.value = resp.data
        }
      }
    }

    watch([videoData], () => {
      if (videoData.value) {
        tabs.value[1].hide = videoData.value.link === null && videoData.value.left_video_url === null && videoData.value.left_video_url === null
        tabs.value[2].hide = (videoData.value.link === null && videoData.value.left_video_url === null && videoData.value.left_video_url === null) || (!hasPermission(82))
      }
    })

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        await initForm()
      } else {
        tab.value = 0
        videoData.value = null
      }
    })

    onMounted(async () => {

    })

    return {
      // data
      tab,
      tabs,
      computedTabs,
      videoData,
      transactionData,
      pageData,
      filterData,
      userViews,
      isAfter30Days,
      computedIsOpen,

      // method
      formatCurrency,
      goBack,
      hasPermission,

      // i18n
      t,

      // icons
      icons: {
        mdiTableArrowLeft,
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiAccountEyeOutline,
        mdiVideoOutline,
      },
    }
  },
}
</script>
